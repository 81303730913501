import * as React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import './ourworks.style.scss';

const OurWorks = () => (
  <section className='latest-works'>
    <div className='latest-works__list'>
      <div className='latest-works__list__left'>
        <div className='latest-works__list__item'>
          <div className='latest-works__list__item__content'>
            <Link title='Fr. Michael Fritz' to='/works/fritz/' className='transform-scale-h'>
              <StaticImage
                width={900}
                quality={95}
                formats={['auto', 'webp', 'avif']}
                src='../../images/works/card--fritz.png'
                alt='Michael Fritz Letterhead Sample Card'
              />
              <h2>Fr. Michael Fritz</h2>
              <h3>Logo Design / Branding</h3>
            </Link>
          </div>
        </div>
        <div className='latest-works__list__item'>
          <div className='latest-works__list__item__content'>
            <Link
              title='Metroland Photo Website'
              to='/works/metroland-photo/'
              className='transform-scale-h'
            >
              <StaticImage
                width={900}
                quality={95}
                formats={['auto', 'webp', 'avif']}
                src='../../images/works/card--metroland.png'
                alt='Metroland Photo website mockup'
              />
              <h2>Metroland Photo</h2>
              <h3>Web Design / Development</h3>
            </Link>
          </div>
        </div>
        <div className='latest-works__list__item'>
          <div className='latest-works__list__item__content'>
            <Link
              title='90 State Events Website'
              to='/works/90-state-events/'
              className='transform-scale-h'
            >
              <StaticImage
                width={900}
                quality={95}
                formats={['auto', 'webp', 'avif']}
                src='../../images/works/card--90state.png'
                alt='90 State Events website mockup'
              />
              <h2>90 State Events</h2>
              <h3>Web Design / Development / SEO</h3>
            </Link>
          </div>
        </div>
        <div className='latest-works__list__item'>
          <div className='latest-works__list__item__content'>
            <Link
              title='Mazzone Catering Website'
              to='/works/mazzone-catering/'
              className='transform-scale-h'
            >
              <StaticImage
                width={900}
                quality={95}
                formats={['auto', 'webp', 'avif']}
                src='../../images/works/card--mazzonecatering.png'
                alt='Mazzone Catering website mockup'
              />
              <h2>Mazzone Catering</h2>
              <h3>Web Design / Development / SEO</h3>
            </Link>
          </div>
        </div>
      </div>

      <div className='latest-works__list__right'>
        <div className='latest-works__list__item'>
          <div className='latest-works__list__item__content'>
            <Link title='Loft 433 Website' to='/works/loft-433/' className='transform-scale-h'>
              <StaticImage
                width={900}
                quality={95}
                formats={['auto', 'webp', 'avif']}
                src='../../images/works/card--loft433.png'
                alt='Loft 433 website mockup'
              />
              <h2>Loft 433</h2>
              <h3>Web Development / SEO</h3>
            </Link>
          </div>
        </div>
        <div className='latest-works__list__item'>
          <div className='latest-works__list__item__content'>
            <Link
              title='Hall of Springs Website'
              to='/works/hall-of-springs/'
              className='transform-scale-h'
            >
              <StaticImage
                width={900}
                quality={95}
                formats={['auto', 'webp', 'avif']}
                src='../../images/works/card--hallofsprings.png'
                alt='Metroland Photo website mockup'
              />

              <h2>Hall of Springs</h2>
              <h3>Web Design / Development / SEO</h3>
            </Link>
          </div>
        </div>
        <div className='latest-works__list__item'>
          <div className='latest-works__list__item__content'>
            <Link
              title='Glen Sanders Mansion Website'
              to='/works/glen-sanders-mansion/'
              className='transform-scale-h'
            >
              <StaticImage
                width={900}
                quality={95}
                formats={['auto', 'webp', 'avif']}
                src='../../images/works/card--gsm.png'
                alt='Glen Sanders Mansion website mockup'
              />
              <h2>Glen Sanders Mansion</h2>
              <h3>Web Design / Development / SEO</h3>
            </Link>
          </div>
        </div>
        <div className='latest-works__list__item'>
          <div className='latest-works__list__item__content'>
            <Link
              title='Mazzone Hospitality Website'
              to='/works/mazzone-hospitality/'
              className='transform-scale-h'
            >
              <StaticImage
                width={900}
                quality={95}
                formats={['auto', 'webp', 'avif']}
                src='../../images/works/card--mazzone.png'
                alt='Mazzone Hospitality website mockup'
              />
              <h2>Mazzone Hospitality</h2>
              <h3>Web Development / SEO</h3>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default OurWorks;

import * as React from 'react';
import './works.style.scss';

import Seo from '../components/seo';
import PageTitle from '../components/pagetitle';
import PageContent from '../components/pagecontent';
import OurWorks from '../components/ourworks';
import Cta from '../components/cta';

const IndexPage = () => (
  <main className='site-main'>
    <Seo
      title='Our Work, Join Them '
      description='Come join the growing list of clients who are not settling for a normal website and instead are looking to create a customer experience'
    />
    <section className='works section'>
      <PageTitle
        pageTitle='Our Work'
        pageTagline='Let Us Tell Your '
        pageHighlight='Story'
        pageClone='Bespoke'
      />
      <PageContent
        heading=' Your Project Sure Would Look Good Here'
        contentText='We firmly believe that every brand and every project is unique and
          deserves a tailor-made solution.'
      />
      <OurWorks />
    </section>
    <Cta ctaTitle='Like our work?' />
  </main>
);

export default IndexPage;
